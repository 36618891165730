import styled from 'styled-components';

import { useTheme } from '@/services/theme';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { breakpoints } from '@/shared/styles';

import { ReactComponent as Chainsecurity } from './chainsecurity.svg';
import { ReactComponent as OpenZeppelin } from './openzeppelin.svg';
import { ReactComponent as Sherlock } from './sherlock.svg';
import { ReactComponent as Statemind } from './statemind.svg';

const SecurityContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;

  .Security__header_container {
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 28px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: span 2;

    @media (min-width: ${breakpoints.tabletSM}px) {
      grid-column: span 2;
      padding: 58px;
      border-radius: 56px;
    }
  }

  .Security__icon_container {
    min-height: 100px;
  }

  .Security__container {
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 56px;
    padding: 58px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .Security__title {
    font-size: 36px;
    line-height: 47px;
    font-weight: 700;
    color: #fff;
  }

  .Security__description {
    color: #b4b7cc;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  .Security__description_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    font-family: Gramatika;
  }

  .Security__audits_container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      flex-direction: row;
    }
  }

  .Security__audit_container {
    width: 100%;
    border: 1px solid #292a35;
    border-radius: 32px;
    padding: 52px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    & svg {
      width: 200px;
      height: 42px;
    }
  }
`;

const Link = styled.a`
  font-family: Gramatika;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
  color: #18191f;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  transition: opacity 300ms;

  &:focus,
  &:hover {
    opacity: 0.9;
  }

  & svg {
    fill: #a6a7b0;
    width: 14px !important;
    height: 14px !important;
  }
`;

export const Security = () => {
  const { theme } = useTheme();

  return (
    <SecurityContainer>
      <div className="Security__header_container">
        <h2>Security</h2>
        <div className="Content__description1">
          Institutional-grade tooling for the decentralized finance space, empowering builders to
          create complex financial strategies with minimal effort.
        </div>

        <div className="Security__audits_container">
          <div className="Security__audit_container">
            <div className="Security__audit_logo">
              <OpenZeppelin />
            </div>
            <Link
              href="https://github.com/mellow-finance/mellow-lrt/blob/fixes/audit-sherlock-fixes/audits/202409_OpenZeppelin/Mellow%20Simple%20DVT%20Strategy%20Audit.pdf"
              target="_blank"
              rel="noreferrer"
              theme={theme}
            >
              OpenZeppelin
              <SpriteIcon icon="external-link" />
            </Link>
          </div>

          <div className="Security__audit_container">
            <div className="Security__audit_logo">
              <Chainsecurity />
            </div>
            <Link
              href="https://github.com/mellow-finance/mellow-lrt/blob/fixes/audit-sherlock-fixes/audits/202408_ChainSecurity/ChainSecurity_Mellow_Finance_Mellow_LRT_audit.pdf"
              target="_blank"
              rel="noreferrer"
              theme={theme}
            >
              Chainsecurity
              <SpriteIcon icon="external-link" />
            </Link>
          </div>

          <div className="Security__audit_container">
            <div className="Security__audit_logo">
              <Statemind />
            </div>
            <Link
              href="https://github.com/mellow-finance/mellow-lrt/blob/main/audits/202405_Statemind/Mellow%20LRT%20Final%20report.pdf"
              target="_blank"
              rel="noreferrer"
              theme={theme}
            >
              Statemind
              <SpriteIcon icon="external-link" />
            </Link>
          </div>

          <div className="Security__audit_container">
            <div className="Security__audit_logo">
              <Sherlock />
            </div>
            <Link
              href="https://github.com/mellow-finance/mellow-lrt/blob/fixes/audit-sherlock-fixes/audits/202406_Sherlock/Mellow%20Modular%20LRTs%20Audit%20Report.pdf"
              target="_blank"
              rel="noreferrer"
              theme={theme}
            >
              Sherlock
              <SpriteIcon icon="external-link" />
            </Link>
          </div>
        </div>
      </div>
    </SecurityContainer>
  );
};
