import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { useTheme, Theme } from '@/services/theme';
import { landingHeaderLinks } from '@/shared/mocks/navigationLinks';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { breakpoints } from '@/shared/styles';
import { Logo } from '@/shared/components/Logo';
import { Typography } from '@/shared/components/Typography';
import { formatNumber } from '@/shared/utils';
import { useVaultsData } from '@/pages/RestakangLandingPage/useLandingData';

type Props = {
  isMenuOpen: boolean;
  hidden: boolean;
  toggleMenu: () => void;
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: ${(props: { hidden: boolean }) => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  width: 100%;
  max-width: 0;
  min-height: 100vh;
  max-width: ${(props: { $isOpen: boolean }) => (props.$isOpen ? '100%' : 'auto')};
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background.dark};
  padding-bottom: 60px;

  nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 85px;
    padding: 0 16px;

    > a {
      text-decoration: none;
    }
  }

  .MobileMenu__section {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .MobileMenu__column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .MobileMenu__footer {
    padding-bottom: 40px;
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
`;

const NavLink = styled(Link)`
  font-weight: ${({ $isCurrentPath }: { $isCurrentPath: boolean }) => ($isCurrentPath ? 700 : 400)};
  text-decoration: none;
  font-family: Gramatika;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fff;

  &:hover {
    text-decoration: underline solid #fff;
  }
`;

const CloseButton = styled.div`
  border-radius: 50%;
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const StyledLogo = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 2.3rem;

  @media (max-width: ${breakpoints.tabletLG}px) {
    max-width: 95px;
  }
  @media (max-width: ${breakpoints.mobileLG}px) {
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    display: inline-flex;
    gap: 1.3rem;
  }
`;

export const MobileMenu = ({ isMenuOpen = false, hidden = true, toggleMenu }: Props) => {
  const { theme } = useTheme();
  const { pathname } = useLocation();
  const { data: vaultsData } = useVaultsData();

  const tvl = (vaultsData || []).reduce((acc, vault) => acc + parseFloat(vault.tvl_usd), 0);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isMenuOpen]);

  return createPortal(
    <Container theme={theme} $isOpen={isMenuOpen} hidden={hidden}>
      <nav>
        <HashLink onClick={toggleMenu} to="#top" smooth>
          <StyledLogo>
            <Logo />
          </StyledLogo>
        </HashLink>

        <CloseButton type="button" onClick={toggleMenu}>
          <SpriteIcon icon="x-cross" color="#2a2c33" size={1.2} />
        </CloseButton>
      </nav>

      <div className="MobileMenu__section">
        <div className="MobileMenu__column">
          <NavLink $isCurrentPath={pathname === '/'} to="/">
            Homepage
          </NavLink>

          <NavLink $isCurrentPath={pathname === '/structured'} to="/structured">
            Structured Products
          </NavLink>

          <NavLink to="https://docs.mellow.finance">Documentation</NavLink>

          {landingHeaderLinks.social.map(item => {
            const rel = item.target === '_blank' ? 'noopener noreferrer' : '';

            return (
              <NavLink
                key={item.name}
                to={item.to}
                target={item.target}
                rel={rel}
                title={item.title}
              >
                {item.name}
              </NavLink>
            );
          })}
        </div>

        {tvl && (
          <div className="MobileMenu__footer">
            <Typography variant="body1" size="13px" fontFamily="gramatika" weight={700}>
              TVL:
            </Typography>
            <Typography variant="body1" size="13px" fontFamily="gramatika" weight={400}>
              ${formatNumber(tvl)}
            </Typography>
          </div>
        )}
      </div>
    </Container>,
    document.getElementById('modal') as HTMLDivElement,
  );
};
