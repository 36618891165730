import styled from 'styled-components';

import { useTheme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

import { ReactComponent as HiddenComplexityIcon } from './hidden_complexity.svg';
import { ReactComponent as PermissionlessIcon } from './permissionless.svg';
import { ReactComponent as ModularityIcon } from './modularity.svg';
import { ReactComponent as ZeroDevelopmentIcon } from './zero_development.svg';

const FeaturesContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  position: relative;
  align-items: center;
  height: 100%;

  width: calc(100vw - 40px);
  max-width: 1200px;

  @media (min-width: ${breakpoints.tabletSM}px) {
    grid-template-columns: 1fr 1fr;
  }

  .Feature__container {
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 38px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    gap: 20px;

    max-width: calc(100vw - 40px);

    @media (min-width: ${breakpoints.tabletSM}px) {
      padding: 58px;
      border-radius: 56px;
    }
  }

  .Feature__icon_container {
    width: 25%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${breakpoints.tabletSM}px) {
      width: 40%;
      height: 160px;
    }
  }

  .Feature__description_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
    text-align: left;

    @media (min-width: ${breakpoints.tabletSM}px) {
      gap: 10px;
    }
  }
`;

export const Features = () => {
  const { theme } = useTheme();
  return (
    <FeaturesContainer theme={theme}>
      <div className="Feature__container">
        <div className="Feature__icon_container">
          <PermissionlessIcon />
        </div>
        <div className="Feature__description_container">
          <h4>Permissionless</h4>
          <div className="Content__description3">
            No intermediaries — design and deploy your own investment product.
          </div>
        </div>
      </div>

      <div className="Feature__container">
        <div className="Feature__icon_container">
          <ModularityIcon />
        </div>
        <div className="Feature__description_container">
          <h4>Modularity</h4>
          <div className="Content__description3">
            From new assets and strategies to AI agents. Flexibility is the name of the game.
          </div>
        </div>
      </div>

      <div className="Feature__container">
        <div className="Feature__icon_container">
          <HiddenComplexityIcon />
        </div>
        <div className="Feature__description_container">
          <h4>Hidden complexity</h4>
          <div className="Content__description3">
            With Meta LRT, sophisticated yield strategies are easy both to create and use.
          </div>
        </div>
      </div>

      <div className="Feature__container">
        <div className="Feature__icon_container">
          <ZeroDevelopmentIcon />
        </div>
        <div className="Feature__description_container">
          <h4>Zero Development</h4>
          <div className="Content__description3">
            Mellow is designed with minimal technical barriers, unlocking broader capital.
          </div>
        </div>
      </div>
    </FeaturesContainer>
  );
};
