import styled, { css } from 'styled-components';
import { forwardRef } from 'react';

import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';

import { addRem } from '../../styles';
import { Spinner } from '../Spinner';

type Type = 'reset' | 'button' | 'submit' | 'link';

type Variant = 'primary' | 'secondary' | 'ghost' | 'link';

type Size = 'xs' | 'md' | 'lg';

type NewButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'size'> & {
  variant?: Variant;
  isLoading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  emulateHover?: boolean;
  type?: Type;
  fullwidth?: boolean;
  size?: Size;
  margin?: string;
  mx?: string | number;
  my?: string | number;
  mt?: string | number;
  mr?: string | number;
  mb?: string | number;
  ml?: string | number;
  gap?: string | number;
};

type StyledProps = {
  $isHovered: boolean;
  variant?: Variant;
  theme: Theme;
  $fullwidth: boolean;
  $size?: 'xs' | 'md';
};

const variantStyles = ({ theme, variant = 'primary', $isHovered }: StyledProps) =>
  ({
    primary: css`
      color: #ffffff;
      background: ${$isHovered ? theme.colors.primary : theme.colors.primary};

      &:disabled {
        color: ${({ theme }: StyledProps) => theme.colors.text.primary};
      }

      &:hover,
      &:focus {
        color: #ffffff;
        background: ${({ theme }: StyledProps) => theme.colors.primaryLight};
      }

      &:active {
        background: ${({ theme }: StyledProps) => theme.colors.primaryLight};
      }
    `,
    secondary: css`
      color: ${({ theme }: StyledProps) => theme.colors.text.primary};
      background: ${({ theme }: StyledProps) => theme.colors.newGray7};

      &:hover,
      &:focus {
        background: ${({ theme }: StyledProps) => theme.colors.newGray8};
      }

      &:active {
        background: ${({ theme }: StyledProps) => theme.colors.newGray8};
      }
    `,
    ghost: css`
      background: transparent;

      &:hover,
      &:focus {
        background: ${({ theme }: StyledProps) => theme.colors.newGray1};
      }

      &:active {
        background: ${({ theme }: StyledProps) => theme.colors.newGray1};
      }
    `,
    link: css`
      background: transparent;
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    `,
  })[variant];

const styleOverrides = ({ gap, margin, mx, my, mt, mr, mb, ml }: NewButtonProps) => css`
  ${gap !== undefined && `gap: ${addRem(gap)};`}
  ${margin !== undefined && `margin: ${margin};`}
  ${mx && `margin-left: ${addRem(mx)}; margin-right: ${addRem(mx)};`}
  ${my && `margin-top: ${addRem(my)}; margin-bottom: ${addRem(my)};`}
  ${mt && `margin-top: ${addRem(mt)};`}
  ${mr && `margin-right: ${addRem(mr)};`}
  ${mb && `margin-bottom: ${addRem(mb)};`}
  ${ml && `margin-left: ${addRem(ml)};`}
`;

const StyledButton = styled.div<StyledProps>`
  transition: background 300ms;
  border: 0;
  border-radius: 50px;
  color: ${({ theme }: StyledProps) => theme.colors.text.primary};
  cursor: pointer;
  font-family: ${({ theme }: StyledProps) => theme.fonts.main};
  font-size: ${({ $size }: StyledProps) => ($size === 'md' ? '1.4rem' : '1.2rem')};
  font-weight: 700;
  line-height: 2rem;
  padding: ${({ $size }: StyledProps) => ($size === 'md' ? '12px 20px' : '6px 16px')};
  width: ${({ $fullwidth }: StyledProps) => ($fullwidth ? '100%' : 'unset')};
  text-decoration: none;
  white-space: nowrap;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  align-self: flex-start;

  &:disabled {
    opacity: 0.5;
    background: ${({ theme }: StyledProps) => theme.colors.newGray4};
    box-shadow: none;
    cursor: default;
  }

  ${(props: StyledProps) => variantStyles(props)};
  ${(props: StyledProps) => styleOverrides(props)};
`;

const NewButton = forwardRef<HTMLButtonElement, NewButtonProps>(
  (
    {
      as = 'button',
      variant = 'primary',
      isLoading,
      leftIcon,
      rightIcon,
      children,
      fullwidth,
      emulateHover,
      size = 'md',
      ...props
    },
    ref,
  ) => {
    const { theme } = useTheme();

    return (
      <StyledButton
        as={as}
        ref={ref}
        variant={variant}
        $fullwidth={fullwidth}
        $isHovered={emulateHover}
        $size={size}
        theme={theme}
        {...props}
      >
        {isLoading ? (
          <Spinner size="1.4rem" thickness="0.2rem" style={{ marginRight: '0.4rem' }} />
        ) : (
          leftIcon
        )}
        <Translated>{children}</Translated>
        {rightIcon}
      </StyledButton>
    );
  },
);

export { NewButton };
