import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';
import { CookieBanner } from '@/features/cookies/CookieBanner';

import { Documentation } from './sections/Documentation';
import { Investors, Partners } from './sections/Investors';
import { Features } from './components/Features';
import { Header } from './components/Header';
import { LandingContainer } from './components/LandingContainer';
import { WelcomeBlock } from './components/WelcomeBlock';
import { TvlRewards } from './sections/TvlRewards';
import { HowItWorks } from './sections/HowItWorks';
import { SupportedTokens } from './sections/SupportedTokens/SupportedTokens';
import { Audits } from './sections/Audits/Audits';
import { Curators } from './sections/Curators';
import { Footer } from './components/Footer';

const LandingWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  min-width: 350px;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  background: ${({ theme }: { theme: Theme }) => theme.colors.background.dark};
  font-family: Helvetica, sans-serif;
`;

const Container = styled.div``;

const Content = styled.div`
  margin-bottom: 91px;

  @media (min-width: ${breakpoints.tabletLG}px) {
    margin-bottom: 192px;
  }

  .block-wrap {
    padding-top: 153px;

    @media (max-width: ${breakpoints.tabletSM}px) {
      padding-top: 64px;
    }
  }

  .landing-page__lottie {
    position: absolute;
    top: -20%;
    left: -20%;
    overflow: hidden;
    max-width: 100%;

    @media (max-width: ${breakpoints.tabletSM}px) {
      max-width: 110%;
      top: -5%;
      left: -40%;
    }
  }
`;

const WelcomeContainer = styled.div`
  width: 100%;
  padding-bottom: 30px;
  @media (min-width: ${breakpoints.tabletLG}px) {
    padding-bottom: 60px;
  }
`;

const RestakeLandingPage = () => {
  const { theme } = useTheme();

  return (
    <LandingWrap theme={theme}>
      <Container theme={theme}>
        <Header color="solid" />
        <Content>
          <main>
            <WelcomeContainer>
              <WelcomeBlock />
            </WelcomeContainer>

            <div>
              <LandingContainer>
                <Features />
              </LandingContainer>
            </div>

            <LandingContainer>
              <Investors />
            </LandingContainer>

            <LandingContainer>
              <TvlRewards />
            </LandingContainer>

            <LandingContainer>
              <HowItWorks />
            </LandingContainer>

            <LandingContainer>
              <SupportedTokens />
            </LandingContainer>

            <LandingContainer>
              <Partners />
            </LandingContainer>

            <LandingContainer>
              <Audits />
            </LandingContainer>

            <LandingContainer>
              <Documentation />
            </LandingContainer>

            <LandingContainer>
              <Curators />
            </LandingContainer>
          </main>
        </Content>
        <Footer />
        <CookieBanner />
      </Container>
    </LandingWrap>
  );
};

export { RestakeLandingPage };
