import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

const SolutionContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.tabletSM}px) {
    grid-template-columns: 1fr 1fr;
  }

  .Solution__header_container {
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 28px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      grid-column: span 2;
      padding: 58px;
      border-radius: 56px;
    }
  }

  .Solution__container {
    height: 100%;
    background: #18191f;
    border: 1px solid #1f2028;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    border-radius: 28px;
    padding: 24px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      border-radius: 56px;
      padding: 58px;
    }
  }

  .Solution__description {
    & li {
      font-size: 20px;
      padding-inline: 6px;
      margin-left: 15px;
      padding-top: 10px;
    }

    & li::marker {
      color: #ec0b38;
    }
  }
`;

export const Solution = () => (
  <SolutionContainer>
    <div className="Solution__header_container">
      <h2>Mellow Solution</h2>
      <div className="Content__description1 Solution__header_description">
        Institutional-grade tooling for the decentralized finance space. Complex financial
        strategies tailored for the end-users needs.
      </div>
    </div>

    <div className="Solution__container">
      <h3>Institutions & Node Operators</h3>
      <ul className="Content__description3 Solution__description">
        <li>Commoditized onchain yield</li>
        <li>Access to new capital sources</li>
        <li>Native integration into existing infrastructure</li>
        <li>Battle-tested and secure framework</li>
      </ul>
    </div>

    <div className="Solution__container">
      <h3>Structured Products Users</h3>
      <ul className="Content__description3 Solution__description">
        <li>New yield sources for native ecosystem assets</li>
        <li>Unlocking use cases for DeFi and protocols</li>
        <li>Reduced costs of economic security</li>
        <li>TVL growth based on improved APRs</li>
      </ul>
    </div>
  </SolutionContainer>
);
