import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

type Variant = 'primary' | 'secondary';
type Size = 'md' | 'lg';

type Props = LinkProps & {
  variant?: Variant;
  size?: Size;
  children: React.ReactNode;
  to: string;
};

const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${({ size }: { size: Size }) => (size === 'md' ? '14px 28px' : '20px 28px')};
  font-family: ${({ theme }: { theme: Theme }) => theme.fonts.gramatika};
  font-size: ${({ size }: { size: Size }) => (size === 'md' ? '14px' : '16px')};
  line-height: 1.8rem;
  font-weight: 700;
  color: ${({ variant }: { variant: Variant }) => (variant === 'primary' ? '#111217' : '#ffffff')};
  background: ${({ variant }: { variant: Variant }) =>
    variant === 'primary' ? '#ffffff' : '#24262D'};
  border: none;
  border-radius: 30px;
  cursor: pointer;
  cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'auto' : 'pointer')};
  text-decoration: none;
  width: max-content;
  transition: background 300ms;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    font-size: 1.3rem;
    padding: 10px 15px;
  }
`;

export const LinkButton: React.FC<Props> = ({
  children,
  variant = 'primary',
  size = 'md',
  ...props
}) => {
  const { theme } = useTheme();

  return (
    <StyledButton size={size} variant={variant} theme={theme} {...props}>
      {children}
    </StyledButton>
  );
};
