import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import dotenv from 'dotenv';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';

import '@/core/index.css';
import { config } from '@/core/wagmiConfig';
import { Landing } from '@/core/Landing';
import { ModalProvider } from '@/services/modal';
import { ThemeProvider } from '@/services/theme';
import { TranslationProvider } from '@/services/locale';
import { AppConfigProvider } from '@/core/AppConfig';
import { ScrollToTop } from '@/shared/components/ScrollToTop';

dotenv.config();

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <AppConfigProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <ThemeProvider>
              <ModalProvider>
                <TranslationProvider>
                  <Router>
                    <ScrollToTop />
                    <Landing />
                  </Router>
                </TranslationProvider>
              </ModalProvider>
            </ThemeProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </AppConfigProvider>
  </React.StrictMode>,
);
