import { LinkType } from '@/pages/LandingPage/components/NavMenu/NavMenu';

interface ILinks {
  [key: string]: LinkType[];
}

export const landingHeaderLinks: ILinks = {
  nav: [
    { to: '#about', name: 'About', isAnchor: true },
    { to: '#strategies', name: 'Strategies', isAnchor: true },
    { to: '#documentation', name: 'Documentation', isAnchor: true },
    { to: '#research', name: 'Research', isAnchor: true },
  ],
  social: [
    {
      to: 'https://discord.gg/mellow',
      name: 'Discord',
      icon: 'discord',
      target: '_blank',
      title: 'we are on discord',
      size: 1.8,
    },
    {
      to: 'https://x.com/Mellowprotocol',
      name: 'Twitter',
      icon: 'twitter',
      target: '_blank',
      title: 'we are on X',
      size: 1.8,
    },
    {
      to: 'https://mellowprotocol.substack.com/',
      name: 'Substack',
      icon: 'substack-small',
      target: '_blank',
      title: 'we are on substack',
      size: 1.8,
    },
    {
      to: 'https://github.com/mellow-finance/',
      name: 'Github',
      icon: 'github-small',
      target: '_blank',
      title: 'we are on github',
      size: 1.8,
    },
  ],
};

export const landingFooterLinks: ILinks = {
  legal: [
    { to: '/research', name: 'Terms of Service', withBottomBorder: true },
    { to: '/docs', name: 'Privacy Notice', withBottomBorder: true },
    { to: '/terms-service', name: 'Risk Disclosure', withBottomBorder: true },
    { to: '/blog', name: 'Statement', withBottomBorder: true },
  ],
  dev: [
    { to: '/blog', name: 'Blog', withBottomBorder: true },
    { to: '/docs', name: 'Documentation', withBottomBorder: true },
  ],
  social: [
    {
      to: 'https://twitter.com/Mellowprotocol',
      name: 'Twitter',
      target: '_blank',
      withBottomBorder: true,
      isExternal: true,
    },
    {
      to: 'https://mellowprotocol.medium.com/',
      name: 'Medium',
      target: '_blank',
      withBottomBorder: true,
      isExternal: true,
    },
    {
      to: 'https://discord.gg/mellow',
      name: 'Discord',
      target: '_blank',
      withBottomBorder: true,
      isExternal: true,
    },
  ],
};
