import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';
import { useBreakpointDown } from '@/shared/utils';
import { CookieBanner } from '@/features/cookies/CookieBanner';

import { Features } from './sections/Features';
import { Header } from '../RestakangLandingPage/components/Header';
import { Hero } from './sections/Hero';
import { Solution } from './sections/Solution';
import { Security } from './sections/Security';
import { StructuredProduct } from './sections/StructuredProduct';
import { Ecosystem } from './sections/Ecosystem';
import { Footer } from '../RestakangLandingPage/components/Footer';
import { Articles } from './sections/Articles';
import { Partners } from './sections/Partners';
import { NodeOperators } from './sections/NodeOperators';

const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  min-width: 350px;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  background: ${({ theme }: { theme: Theme }) => theme.colors.background.dark};
  font-family: Helvetica, sans-serif;
  position: relative;

  .bg__ellipse {
    position: absolute;
    border-radius: 50%;
    z-index: 0;

    &_blue {
      width: 210px;
      height: 211px;
      top: 86px;
      left: calc((100vw - 1200px) / 2 - 133px);

      background: radial-gradient(
        50% 50% at 50% 50%,
        #131d52 25.29%,
        #2750aa 60.8%,
        #a3eaff 92.71%,
        #ffffff 100%
      );
      backdrop-filter: blur(25px);
      z-index: 1;

      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 500px;
        height: 500px;
        top: 172px;
        left: calc((100vw - 1200px) / 2 - 247px);
      }
    }

    &_red {
      width: 324px;
      height: 325px;
      top: -118px;
      left: -128px;

      background: radial-gradient(50% 50% at 50% 50%, #ea0042 0%, #ec0b38 100%);
      box-shadow: 0px 0px 70px 0px rgba(236, 11, 56, 0.3);
      box-shadow: 0px 0px 100px 0px rgba(255, 255, 255, 1) inset;
      z-index: 0;

      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 767px;
        height: 769px;
        top: -332px;
        left: calc((100vw - 1200px) / 2 - 256px);
      }
    }

    &_red_c1 {
      width: 212px;
      height: 213px;
      top: -62px;
      left: -72px;

      border: 1px solid rgba(255, 255, 255, 0.4);
      z-index: 0;

      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 502px;
        height: 504px;
        top: -201px;
        left: calc((100vw - 1200px) / 2 - 123px);
      }
    }

    &_red_c2 {
      width: 447px;
      height: 449px;
      top: -180px;
      left: -190px;

      border: 1px solid rgba(47, 50, 63, 1);
      z-index: 0;

      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 1060px;
        height: 1061px;
        top: -478px;
        left: calc((100vw - 1200px) / 2 - 402px);
      }
    }
  }
`;

const Content = styled.main`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 91px;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  font-family: Gramatika;

  @media (min-width: ${breakpoints.tabletLG}px) {
    margin-bottom: 192px;
  }

  @media (min-width: 1240px) {
    padding: 0;
  }

  & h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 400;
    color: #fff;
    z-index: 1;

    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 72px;
      line-height: 93px;
    }
  }

  & h3 {
    font-size: 24px;
    line-height: 27px;
    font-weight: 700;
    color: #fff;

    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 32px;
      line-height: 47px;
    }
  }

  & h4 {
    font-size: 24px;
    line-height: 41.6px;
    font-weight: 400;
    color: #fff;

    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 32px;
    }
  }

  .Content__description1 {
    color: #b4b7cc;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 30px;
      line-height: 42px;
    }
  }

  .Content__description2 {
    color: #b4b7cc;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .Content__description3 {
    color: #b4b7cc;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 16px;
    }
  }
`;

const HeroContainer = styled.div`
  width: 100%;
  padding-bottom: 80px;
  @media (min-width: ${breakpoints.tabletLG}px) {
    padding-bottom: 140px;
  }

  & h1 {
    font-weight: 400;
    line-height: 140%;
    font-family: Gramatika;
    text-align: left;

    font-size: 36px;
    @media (min-width: ${breakpoints.tabletSM}px) {
      font-size: 72px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 44px;
  border: 2px solid #1f2028;
  border-radius: 44px;

  @media (min-width: ${breakpoints.tabletSM}px) {
    height: 72px;
    border: 4px solid #1f2028;
    border-radius: 72px;
  }
`;

export const RestakingSolutionLandingPage = () => {
  const { theme } = useTheme();

  const isMobile = useBreakpointDown(425);

  return (
    <Background theme={theme}>
      <div className="bg__ellipse bg__ellipse_blue" />
      <div className="bg__ellipse bg__ellipse_red" />
      <div className="bg__ellipse bg__ellipse_red_c1" />
      <div className="bg__ellipse bg__ellipse_red_c2" />

      <Header color="transparent" scrollYThreshold={isMobile ? 106 : 180} />
      <HeroContainer>
        <Hero />
      </HeroContainer>
      <Content>
        <Partners />
        <Features />
        <Divider />
        <Solution />
        <Divider />
        <StructuredProduct />
        <Divider />
        <NodeOperators />
        <Divider />
        <Security />
        <Divider />
        <Ecosystem />
        <Divider />
        <Articles />
      </Content>
      <Footer />
      <CookieBanner />
    </Background>
  );
};
