import { equals, findIndex, keys } from 'ramda';
import { createContext, useState } from 'react';

import { themes, Theme, ThemeOverride, mergeThemes } from '@/shared/themes';
import { getFromLocalStorage, setToLocalStorage } from '@/shared/utils';

const THEME_KEY = 'dark';

type ThemeKey = keyof typeof themes;

type ThemeContextType = {
  theme: Theme;
  themeKey: ThemeKey;
  setThemeKey: (key: string) => void;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: themes.dark,
  themeKey: 'dark',
  setThemeKey: () => {},
  toggleTheme: () => {},
});

export const ThemeProvider = ({
  override,
  children,
}: {
  override?: ThemeOverride;
  children: React.ReactNode;
}) => {
  const localStorageTheme = getFromLocalStorage(THEME_KEY) || 'dark';
  const [themeKey, setThemeKeyState] = useState<ThemeKey>(localStorageTheme);
  const theme = themes[themeKey];

  function setThemeKey(key: string) {
    setThemeKeyState(key as ThemeKey);
    setToLocalStorage(THEME_KEY, key);
  }

  function toggleTheme() {
    const themeKeys = keys(themes);
    let idx = findIndex(equals(themeKey), themeKeys);
    idx = (idx + 1) % themeKeys.length;
    setThemeKey(themeKeys[idx]);
  }

  return (
    <ThemeContext.Provider
      value={{ theme: mergeThemes(theme, override), themeKey, setThemeKey, toggleTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
