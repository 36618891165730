/* eslint-disable no-restricted-syntax */
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { useDimensions } from '@/shared/utils/useDimensions';
import { useTheme } from '@/services/theme';
import { landingHeaderLinks } from '@/shared/mocks/navigationLinks';
import { breakpoints } from '@/shared/styles';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Logo } from '@/shared/components/Logo';
import { formatNumber } from '@/shared/utils';
import { APP_DOMAIN } from '@/core/config';

import { MobileMenu } from './components/MobileMenu';
import { LandingContainer } from '../LandingContainer';
import { useVaultsData } from '../../useLandingData';
import { LinkButton } from '../LinkButton';
import { ReactComponent as Burger } from './burger.svg';

type ColorVariant = 'solid' | 'transparent';

type ScrollDirection = 'up' | 'down';

const StyledHeader = styled.header`
  position: sticky;
  top: ${({ $show }: { $show: boolean }) => ($show ? '0px' : '-96px')};
  z-index: 1000;
  height: 85px;
  background: ${({ $isScrolledTop, color }: { $isScrolledTop: boolean; color: ColorVariant }) => {
    if ($isScrolledTop || color === 'solid') {
      return 'rgba(18, 19, 23, 0.95)';
    }
    return 'transparent';
  }};
  backdrop-filter: ${({
    $isScrolledTop,
    color,
  }: {
    $isScrolledTop: boolean;
    color: ColorVariant;
  }) => {
    if ($isScrolledTop || color === 'solid') {
      return 'blur(40px)';
    }
    return 'none';
  }};
  transition:
    background 0.4s ease,
    top 500ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: ${breakpoints.tabletLG}px) {
    height: 96px;
  }
`;

const Wrap = styled.div`
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > a {
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.tabletLG}px) {
    padding: 29px 0;
    height: 96px;
  }
`;

const NavLink = styled(Link)`
  font-family: Gramatika;
  font-size: 13px;
  font-weight: ${({ $isCurrentPath }: { $isCurrentPath: boolean }) => ($isCurrentPath ? 700 : 400)};
  line-height: 17.88px;
  text-align: left;
  text-decoration: none;
  color: #fff;

  &:hover {
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.tabletLG}px) {
    font-size: 15px;
  }
`;

const NavbarContainer = styled.nav`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 20px;
  gap: 20px;
  background: ${({ $isScrolledTop, color }: { $isScrolledTop: boolean; color: ColorVariant }) => {
    if ($isScrolledTop) {
      return 'transparent';
    }
    if (color === 'transparent') {
      return 'rgba(18, 19, 23, 0.4)';
    }
    return '#20222a';
  }};
  backdrop-filter: ${({
    $isScrolledTop,
    color,
  }: {
    $isScrolledTop: boolean;
    color: ColorVariant;
  }) => {
    if ($isScrolledTop) {
      return 'none';
    }
    if (color === 'transparent') {
      return 'blur(40px)';
    }
    return 'none';
  }};
  transition: background 0.4s ease;
  border-radius: 50px;

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-family: Gramatika;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.88px;
  }

  @media (min-width: ${breakpoints.tabletLG}px) {
    .section {
      font-size: 15px;
    }
  }

  .section_tvl {
    font-weight: 700;
  }
`;

const Divider = styled.div`
  height: 24px;
  width: 1px;
  background: #40424d;
`;

const MenuButton = styled.button`
  outline: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  &:hover {
    opacity: 0.9;
  }
`;

const StyledIconLink = styled.a`
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:hover {
    opacity: 0.9;
  }
`;

const StyledLogo = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 2.3rem;

  @media (max-width: ${breakpoints.tabletLG}px) {
    max-width: 95px;
  }
  @media (max-width: ${breakpoints.mobileLG}px) {
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    display: inline-flex;
    gap: 1.3rem;
  }
`;

const Navbar = ({ isScrolledTop, color }: { isScrolledTop: boolean; color: ColorVariant }) => {
  const { theme } = useTheme();
  const { innerWidth } = useDimensions();
  const isTabletSize = innerWidth <= breakpoints.tabletLG;
  const { isLoading, data: vaultsData } = useVaultsData();
  const { pathname } = useLocation();

  const tvl = (vaultsData || []).reduce((acc, vault) => acc + parseFloat(vault.tvl_usd), 0);

  if (isTabletSize) {
    return null;
  }

  if (isLoading || !vaultsData) {
    return (
      <ContentLoader
        speed={2}
        width="640px"
        height="48px"
        backgroundColor={color === 'solid' ? theme.colors.card : 'rgba(18, 19, 23, 0.4)'}
        foregroundColor={color === 'solid' ? theme.colors.newGray2 : 'rgba(18, 19, 23, 0.5)'}
        style={{ borderRadius: '50px', backdropFilter: 'blur(40px)' }}
      >
        <rect x="0" y="0" width="100%" height="100%" />
      </ContentLoader>
    );
  }

  return (
    <NavbarContainer $isScrolledTop={isScrolledTop} color={color}>
      <NavLink $isCurrentPath={pathname === '/'} to="/">
        Homepage
      </NavLink>

      <NavLink $isCurrentPath={pathname === '/structured'} to="/structured">
        Structured Products
      </NavLink>

      <NavLink to="https://docs.mellow.finance">Documentation</NavLink>

      <Divider />

      {landingHeaderLinks.social.map(item => {
        const rel = item.target === '_blank' ? 'noopener noreferrer' : '';
        if (!item.icon) {
          return null;
        }

        return (
          <StyledIconLink
            key={item.name}
            href={item.to}
            target={item.target}
            rel={rel}
            title={item.title}
          >
            <SpriteIcon icon={item.icon} color="#fff" size={item.size} />
          </StyledIconLink>
        );
      })}

      <Divider />

      <div className="section">
        <span className="section_tvl">TVL:</span>
        <span>${formatNumber(tvl)}</span>
      </div>
    </NavbarContainer>
  );
};

type HeaderProps = {
  color: ColorVariant;
  scrollYThreshold?: number;
};

export const Header = ({ color = 'solid', scrollYThreshold = 96 }: HeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolledTop, setIsScrolledTop] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null);
  const { innerWidth } = useDimensions();
  const { theme } = useTheme();
  const isTabletSize = innerWidth <= breakpoints.tabletLG;

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    if (isMenuOpen) {
      toggleMenu();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= scrollYThreshold) {
        if (isScrolledTop) {
          setIsScrolledTop(false);
        }
      } else if (!isScrolledTop) {
        setIsScrolledTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledTop, scrollYThreshold]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleScroll = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection]);

  return (
    <StyledHeader
      theme={theme}
      $isScrolledTop={isScrolledTop}
      color={color}
      $show={!isTabletSize || scrollDirection !== 'down'}
    >
      <LandingContainer maxWidth={1200}>
        <Wrap>
          <Link onClick={handleLogoClick} to="/">
            <StyledLogo>
              <Logo />
            </StyledLogo>
          </Link>

          <Navbar isScrolledTop={isScrolledTop} color={color} />

          {isTabletSize ? (
            <MenuButton onClick={toggleMenu}>
              <Burger />
            </MenuButton>
          ) : (
            <LinkButton variant="primary" to={`${APP_DOMAIN}/vaults`} target="_blank">
              Open dApp
            </LinkButton>
          )}
        </Wrap>
      </LandingContainer>

      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} hidden={!isTabletSize} />
    </StyledHeader>
  );
};
