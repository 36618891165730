/* eslint-disable no-bitwise */
const dark = {
  name: 'dark',
  fonts: {
    heading: ["'Roboto Mono'", "'DejaVu Sans Mono'", 'monospace'].join(', '),
    main: ['Roboto', "'Open Sans'", 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    body: ['Roboto', "'Open Sans'", 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    mono: ["'Roboto Mono'", "'DejaVu Sans Mono'", 'monospace'].join(', '),
    noto: ["'Noto Sans'", "'Open Sans'", 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    notoMono: ["'Noto Sans Mono'", "'DejaVu Sans Mono'", 'monospace'].join(', '),
    gramatika: 'Gramatika',
    helvetica: 'Helvetica',
  },
  colors: {
    primary: '#4865FF',
    primaryLight: '#668FFF',
    newGray1: '#1F2028',
    newGray2: '#23252D',
    newGray3: '#2A2C35',
    newGray4: '#2F303A',
    newGray5: '#333641',
    newGray6: '#414450',
    newGray7: '#4A4E5C',
    newGray8: '#636372',
    newGray9: '#7E859A',
    newGray10: '#8D92A3',
    newGray11: '#ACB1C1',
    card: '#18191F',
    primaryPurple: '#9B5EFF',
    pageBg: '#101115',
    restakePink: '#FF4894',

    accent: '#8c5bff',
    white: '#ffffff',
    pink1: '#f579e8',
    pink2: '#F67DE9',
    pink3: '#f18fee',
    pink4: '#f788f3',
    pink5: '#e05dbc',
    pink6: '#ff63ae',
    slateBlue: '#7b4ceb',
    blue: '#5840e7',
    purple: '#a175fd',
    purpleAccent: '#9143f3',
    purpleChecked: '#A158FC',
    purple2: '#a238ec80',
    purple3: '#a238ecb3',
    purple4: '#b84ceb',
    purple5: '#ab31ff',
    purple6: '#8043f4',
    purple7: '#7043f4',
    purple8: '#5c43f3',
    purple9: '#8256ff',
    purple10: '#9e50ff',
    lime: '#7cfda0',
    lime2: '#00FF00',
    magenta: '#EA38D7',

    text: {
      primary: '#ffffff',
      secondary: '#c5c5d2',
      tertiary: '#707082',
      underline: '#4d4d5c',
      black: '#000',
      description: '#898c9e',
    },

    shadow1: 'rgba(255, 255, 255, 0.1)',
    shadow2: 'rgba(0, 0, 0, 0.15)',
    shadow3: '#ffffff1a',
    shadow4: '#00000033',
    lightShadow: 'rgba(255, 255, 255, 0.3)',
    purpleShadow1: '#ed38d680',
    purpleShadow2: '#ed38d699',

    success: '#5dde81',
    lighterSuccess: '#7eedab',
    error: '#ff6d6d',
    warn: '#FFD15A',

    gray1: '#B7B7CB',
    gray2: '#9B9BAF',
    gray3: '#848498',
    gray4: '#707082',
    gray5: '#5A5A6A',
    gray6: '#484856',
    gray7: '#3E3E4A',
    gray8: '#34343F',
    gray9: '#2B2B34',
    gray10: '#25252D',
    gray11: '#202027',
    gray12: '#6d6d7a',
    gray13: '#a4a4b0',
    gray14: '#555565',
    gray15: '#636375',
    gray16: '#464654',
    gray17: '#31313c',
    gray18: '#4b4b59',
    gray19: '#c6c6db',
    gray20: '#d3d3e1',
    gray21: '#5f5f71',
    gray22: '#373742',
    gray23: '#757588',
    gray24: '#33333e',
    gray25: '#9393a0',
    gray26: '#4f4f61',
    gray27: '#515161',
    gray28: '#A6A6B2',
    grayHover: '#3F3F4C',

    highlight: '#D1FFE9',

    background: {
      card: '#2a2a33',
      dark: '#101115',
      link: '#30303a',
      badge: '#282830',
      footer: '#19191F',
      blur: 'rgba(29, 30, 37, 0.8)',
      tooltip: '#15151a',
      icon: '#383842',
      input: '#444450',
      modal: '#1b1b21cc',
      disabled: 'rgba(90, 90, 106, 0.3)',
      landingButton: '#4865FF',
    },

    border: {
      primary: '#32323c',
      secondary: '#454552',
      ternary: '#35353f',
      translucentDark1: 'rgba(255, 255, 255, 0.15)',
      translucentDark2: 'rgba(255, 255, 255, 0.04)',
      translucentDisabled: 'rgba(255, 255, 255, 0.07)',
      graphPillar: '#3A3A47',
      lightDark: '#ffffff33',
      dark: '#ffffff0a',
      darkest: 'rgba(0, 0, 0, 0.35)',
      gray1: '#393943',
      gray2: '#494955',
      gray3: '#3a3a44',
      gray4: '#474756',
      stepper: '#535362',
      divider: '#3d3d49',
    },

    gradients: {
      button: 'linear-gradient(96.98deg, #8C5BFF 0%, #F67DE9 100%)',
      shadow: 'linear-gradient(180deg, rgba(30, 30, 36, 0) 0%, #1E1E24 100%)',
      gray1: 'linear-gradient(180deg, #5d5d6d 0%, rgba(93, 93, 109, 0) 100%)',
      gray2: 'linear-gradient(#ffffff 0%, #c6c6db 100%)',
      pillarAccent: 'linear-gradient(#f788f3 0%, #7B4CEB 100%)',
      linkBg: 'linear-gradient(277.88deg, #F993FC 0%, #824FFA 100%)',
      accent1: 'linear-gradient(#a55cff, #9243f4)',
      gray3: 'linear-gradient(#b7b7cb, #848498)',
      accentBg1: 'linear-gradient(90deg, #b76bf9, #a16cf4)',
      accentBg2: 'linear-gradient(90deg, #ad41f8, #9143f3)',
      indicator: 'linear-gradient(90deg, #ed38d6, #9d38ed)',
      hover: 'linear-gradient(180deg, #ed38d6 0%, #8458ff 100%)',
      bluePurple:
        'linear-gradient(180deg, #f582ff 0%, rgba(184, 199, 255, 0.75) 49.48%, #72ffe6 100%)',
      purple: 'linear-gradient(270deg, #D660FF 0%, #F56BE5 100%);',
    },
  },
};

export function mergeThemes(a: Theme, b?: ThemeOverride): Theme {
  return {
    ...a,
    colors: {
      ...a.colors,
      ...b?.colors,
      text: {
        ...a.colors.text,
        ...b?.colors?.text,
      },
      background: {
        ...a.colors.background,
        ...b?.colors?.background,
      },
      border: {
        ...a.colors.border,
        ...b?.colors?.border,
      },
      gradients: {
        ...a.colors.gradients,
        ...b?.colors?.gradients,
      },
    },
  };
}

export const themes = {
  dark,
};

const lightOverride: ThemeOverride = {
  colors: {
    primary: '#2B59FF',

    card: '#ffffff',
    newGray1: '#F8F8F8',
    newGray2: '#EBECF0',
    newGray3: '#EBECF0',
    newGray4: '#edeef2',
    newGray5: '#edeef2',
    newGray6: '#e1e3ea',
    newGray7: '#e1e3ea',
    newGray8: '#dbdde6',
    newGray11: 'rgba(0,0,0,0.5)',
    gray7: 'rgb(155, 155, 175)',
    shadow4: 'rgba(255, 255, 255, 0.3)',
    background: {
      dark: '#EBECF0',
      card: '#ffffff',
      tooltip: '#F6F6F6',
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(0,0,0,0.5)',
    },
  },
};

export const overrides: { [key: string]: ThemeOverride } = {
  'ethereum-dvsteth': lightOverride,
  'ethereum-re7rwbtc': lightOverride,
  'ethereum-re7rtbtc': lightOverride,
  'ethereum-amphrbtc': lightOverride,
  'ethereum-roeth': lightOverride,
  'ethereum-urlrt': lightOverride,
  'ethereum-steaklrt': lightOverride,
  'ethereum-re7lrt': lightOverride,
  'ethereum-amphreth': lightOverride,
  'ethereum-rsteth': lightOverride,
  'ethereum-rsena': lightOverride,
  'ethereum-rsusde': lightOverride,
  'ethereum-ifseth': lightOverride,
  'ethereum-lugaeth': lightOverride,
  'ethereum-coeth': lightOverride,
  'ethereum-pzeth': lightOverride,
  'ethereum-hceth': lightOverride,
  'ethereum-iseth': lightOverride,
  'holesky-dvsteth': lightOverride,
};

export type Theme = typeof dark;

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type ThemeOverride = DeepPartial<Theme>;

// TODO(0xsign): add support for CSS short #FFF and rgb()/rgba()
export function isColorLight(color: string) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 0 + 2), 16);
  const g = parseInt(hex.substring(2, 2 + 2), 16);
  const b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155;
}
