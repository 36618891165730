import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from '@/shared/styles';
import { LinkButton } from '@/pages/RestakangLandingPage/components/LinkButton';

import article1Cover from './images/article_1.png';
import article2Cover from './images/article_2.png';
import article3Cover from './images/article_3.png';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: Gramatika;

  border: 1px solid #1f2028;
  background: #18191f;
  border-radius: 28px;
  padding: 24px;

  flex-direction: column;

  @media (min-width: ${breakpoints.tabletSM}px) {
    padding: 58px;
    border-radius: 56px;
  }

  .Articles__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .Articles__header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const ArticleContainer = styled(Link)`
  color: #fff;
  text-decoration: none;
  border: 1px solid #1f2028;
  background: #18191f;
  border-radius: 32px;
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${breakpoints.tabletSM}px) {
    border-radius: 32px;
  }

  .Article__title_container {
    padding: 24px;
    font-family: Gramatika;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    @media (min-width: ${breakpoints.tabletSM}px) {
      padding: 32px;
      font-size: 21px;
      line-height: 29.4px;
    }
  }

  .Article__image_container {
    border-radius: 32px;
    min-height: 200px;
    height: 200px;
    width: 100%;
    background: #282a33;
    overflow: hidden;

    & img {
      object-fit: cover;
    }
  }
`;

type Article = {
  title: string;
  href: string;
  imgSrc: string;
  imgAlt: string;
};

const articles: Article[] = [
  {
    title: 'Mellow Vision for Permissionless Onchain Structured Products',
    href: 'https://mellowprotocol.substack.com/p/mellow-vision-for-permissionless',
    imgSrc: article1Cover,
    imgAlt: 'Mellow cover',
  },

  {
    title: 'How we can help decentralize Ethereum with solo staking vaults. Meet DVstETH',
    href: 'https://mellowprotocol.substack.com/p/how-we-can-help-decentralize-ethereum',
    imgSrc: article2Cover,
    imgAlt: 'DVstETH',
  },
  {
    title: 'Aggregation & Isolation of Risks in Restaking Markets',
    href: 'https://mellowprotocol.substack.com/p/aggregation-and-isolation-of-risks',
    imgSrc: article3Cover,
    imgAlt: 'Aggregation & Isolation',
  },
];

export const Articles = () => (
  <Container>
    <div className="Articles__header_container">
      <h2>Articles</h2>
      <LinkButton variant="primary" to="https://mellowprotocol.substack.com" target="_blank">
        All publications
      </LinkButton>
    </div>

    <div className="Articles__container">
      {articles.map((article, idx) => (
        <ArticleContainer key={`article_${idx}`} target="_blank" to={article.href}>
          <div className="Article__image_container">
            <img
              width="100%"
              height="100%"
              loading="lazy"
              alt={article.imgAlt}
              src={article.imgSrc}
            />
          </div>
          <div className="Article__title_container">{article.title}</div>
        </ArticleContainer>
      ))}
    </div>
  </Container>
);
