import styled from 'styled-components';

import { ReactComponent as Balancer } from './balancer.svg';
import { ReactComponent as Ethena } from './ethena.svg';
import { ReactComponent as Fuel } from './fuel.svg';
import { ReactComponent as Gearbox } from './gearbox.svg';
import { ReactComponent as Lido } from './lido.svg';
import { ReactComponent as Marginly } from './marginly.svg';
import { ReactComponent as Pendle } from './pendle.svg';
import { ReactComponent as Silo } from './silo.svg';
import { ReactComponent as Spectra } from './spectra.svg';
import { ReactComponent as Stakestone } from './stakestone.svg';
import { ReactComponent as Swell } from './swell.svg';
import { ReactComponent as Zerolend } from './zerolend.svg';
import { ReactComponent as Zircuit } from './zircuit.svg';

const partners = [
  Silo,
  Balancer,
  Spectra,
  Pendle,
  Zircuit,
  Lido,
  Ethena,
  Gearbox,
  Swell,
  Zerolend,
  Fuel,
  Marginly,
  Stakestone,
];

const Mask = styled.div`
  width: 100%;
  --mask: linear-gradient(
      to left,
      rgba(16, 17, 21, 0) 0%,
      rgba(16, 17, 21, 1) 10%,
      rgba(16, 17, 21, 1) 90%,
      rgba(16, 17, 21, 0) 100%
    )
    100% 50% / 100% 100% repeat-x;

  -webkit-mask: var(--mask);
  mask: var(--mask);
`;

const Slider = styled.div`
  --slider-item-width: 150px;
  --slider-items-count: ${partners.length};

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--slider-item-width) * var(--slider-items-count)));
    }
  }

  margin: auto;
  overflow: hidden;
  position: relative;
  opacity: 0.4;
  z-index: 1;

  .slide-track {
    animation: scroll calc(var(--slider-items-count) * 4s) linear infinite;
    display: flex;
    width: calc(var(--slider-item-width) * 2 * var(--slider-items-count));
  }

  .slide {
    height: 40px;
    width: var(--slider-item-width);

    & svg {
      height: 40px;
      width: var(--slider-item-width);
    }
  }

  @media (min-width: 1240px) {
    max-width: 1200px;
  }
`;

export const Partners = () => (
  <Mask>
    <Slider>
      <div className="slide-track">
        {partners.map((TrustedIcon, index) => (
          <div key={`slide_${index}`} className="slide">
            <TrustedIcon />
          </div>
        ))}
        {partners.map((TrustedIcon, index) => (
          <div key={`slide0_${index}`} className="slide">
            <TrustedIcon />
          </div>
        ))}
      </div>
    </Slider>
  </Mask>
);
