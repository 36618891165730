import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

const NodeOperatorsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.tabletSM}px) {
    grid-template-columns: 1fr 1fr;
  }

  .NodeOperators__header_container {
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 28px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: ${breakpoints.tabletSM}px) {
      grid-column: span 2;
      padding: 58px;
      border-radius: 56px;
    }
  }
`;

export const NodeOperators = () => (
  <NodeOperatorsContainer>
    <div className="NodeOperators__header_container">
      <h2>For Node Operators</h2>
      <div className="Content__description1 NodeOperators__header_description">
        Mellow provides exclusive access to restaking yields while enabling them with a seamless
        entry point for new liquidity. Unlike third-party products, integration is native, allowing
        operators to launch tailored private vaults . Full control over slashing risks and user
        flows, transforming liquidity management into a competitive advantage.
      </div>
    </div>
  </NodeOperatorsContainer>
);
