export const breakpoints = {
  mobileXS: 340,
  mobileSM: 375,
  mobileMD: 425,
  mobileLG: 576,
  mobileXL: 600,
  tabletSM: 768,
  tabletMD: 800,
  tabletLG: 960,
  tabletXL: 1024,
  laptopSM: 1165,
  laptop: 1200,
  desktop: 1440,
} as const;

export type Breakpoint = keyof typeof breakpoints;

export const getHTMLFontSize = () => {
  const { fontSize } = window.getComputedStyle(document.documentElement);
  return Number(fontSize.slice(0, -2));
};

export const pxToRem = (px: number) => px / getHTMLFontSize();

export const remToPx = (rem: number) => rem * getHTMLFontSize();

export const addRem = (size: number | string) => (typeof size === 'number' ? `${size}rem` : size);
