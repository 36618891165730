import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

import bgSm from './ecosystem_sm.svg';
import bg from './ecosystem.svg';

const Container = styled.section`
  border: 1px solid #1f2028;
  background: #18191f;
  border-radius: 38px;
  padding: 24px;

  position: relative;

  background-image: url(${bgSm});
  background-repeat: no-repeat;
  background-size: 100% 448px;
  height: 400px;

  @media (min-width: ${breakpoints.tabletSM}px) {
    background-image: url(${bg});
    background-size: 100% 876px;
    height: 876px;
    padding: 58px;
    border-radius: 56px;
  }
`;

export const Ecosystem = () => (
  <Container>
    <h2>Architecture</h2>
  </Container>
);
