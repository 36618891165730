import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { useTheme } from '@/services/theme';
import { APP_DOMAIN } from '@/core/config';
import { LinkButton } from '@/pages/RestakangLandingPage/components/LinkButton';

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  margin: 0 16px;
  @media (min-width: ${breakpoints.tabletSM}px) {
    margin: 0 auto;
  }

  padding-top: 80px;
  @media (min-width: ${breakpoints.tabletSM}px) {
    padding-top: 160px;
    padding-bottom: 100px;
  }

  .Hero__explore_container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;

    @media (min-width: ${breakpoints.tabletSM}px) {
      justify-content: flex-start;
      margin-top: 40px;
    }
  }
`;

const TitleContainer = styled.div`
  z-index: 10;
  width: 100%;
  padding: 30px 30px;

  h1 {
    text-align: center;
    max-width: 1200px;
  }

  @media (min-width: ${breakpoints.tabletSM}px) {
    padding-left: calc((100vw - 1200px) / 2 + 300px);

    h1 {
      text-align: left;
    }
  }
`;

export const Hero = () => {
  const { theme } = useTheme();

  return (
    <HeroContainer id="hero" theme={theme}>
      <TitleContainer>
        <h1>Commoditizing Onchain Yield with Structured Products</h1>

        <div className="Hero__explore_container">
          <LinkButton to={`${APP_DOMAIN}/vaults`} target="_blank" variant="primary" size="lg">
            OPEN DAPP
          </LinkButton>
          <LinkButton
            to="https://forms.gle/XuYcYnSLAc5YvZk56"
            target="_blank"
            variant="secondary"
            size="lg"
          >
            CONTACT US
          </LinkButton>
        </div>
      </TitleContainer>
    </HeroContainer>
  );
};
