import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

import bg from './structured_product.svg';

const Container = styled.section`
  border: 1px solid #1f2028;
  background: #18191f;
  border-radius: 28px;
  padding: 24px;

  position: relative;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 50% 100px;
  background-size: 100% 250px;
  height: 400px;

  @media (min-width: ${breakpoints.tabletSM}px) {
    padding: 58px;
    border-radius: 56px;
    background-position: 50% 200px;
    background-size: 100% 550px;
    height: 800px;
  }
`;

export const StructuredProduct = () => (
  <Container>
    <h2>Structured Product</h2>
  </Container>
);
